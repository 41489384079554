import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const tagUrl = `${Server.ApiURL}/api/tags`;

class TagDataAccessor {

    static Save(data) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: tagUrl,
                method: 'POST',
                data
            }
    
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }

    static getTags() {
        return new Promise((resolve, reject) => {
            const request = {
                URL: tagUrl,
                method: 'GET',
            }
    
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result.data)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }

    static getTag(tagId) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/tags/${tagId}`,
                method: 'GET',
            }
    
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result.data)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }

    static getDetailedTags(data) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: tagUrl + "/detailed",
                method: 'POST',
                data: data
            }
    
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result.data)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }


}

export default TagDataAccessor;