import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const accountUrl = `${Server.ApiURL}/api/transactions`;
const saveTransactionUrl =  `${Server.ApiURL}/api/transaction`;


class TransactionDataAccessor {

    static getTransactions(filter) {

        return new Promise((resolve, reject) => {
            const request = {
                URL: accountUrl,
                method: 'POST',
                data: filter
            };

             fetchData(request)            
          .then((response) => {  
              HandleResponse(response)
                  .then((result) => {
                      resolve(result)
              })
          })
          .catch((err) => {
              console.log('Error caught!', err);
              reject(err);
          });
        });
    }

    static getTransaction(transactionId) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/transaction/${transactionId}`,
                method: 'GET'
            };
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
                })
    }

    static addAttachment(id, fileData) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/attachment/${id}`,
                method: 'POST',
                data: fileData,
                contentType: 'multipart/form-data'
            };

            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }

    static matchTransactions(id, fileData) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/import/${id}`,
                method: 'POST',
                data: fileData,
                contentType: 'multipart/form-data'
            };

            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                    })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }

    static search(searchRequest) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/transaction/search`,
                method: 'POST',
                data:  searchRequest
            };

            fetchData(request)
                .then((response) => {
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }
    
    static getTransactionForSchedule(scheduleId, paymentNumber) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/transaction/schedule/${scheduleId}/paymentnumber/${paymentNumber}`,
                method: 'GET'
            };

            fetchData(request)            
            .then((response) => {  
                HandleResponse(response)
                    .then((result) => {
                        resolve(result)
                })
            })
            .catch((err) => {
                console.log('Error caught!', err);
                reject(err);
            });
        })
    }

    static deleteTransaction(id) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/transaction/${id}`,
                method: 'DELETE'
            };

            fetchData(request) 
                .then((response) => {
                    resolve(response)
                })
        })
    }

    static bulkUpdateTransactions(payload) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/transaction/bulkupdate`,
                method: 'POST',
                data: payload
            };

            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }


    static saveQuickTransaction(transaction) {
        return new Promise((resolve, reject) => {
            const request = {
                URL: `${Server.ApiURL}/api/transaction/quick`,
                method: 'POST',
                data:  transaction 
            };
            
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    reject(err);
                });
        })
    }

    static saveTransaction(transaction) {

        return new Promise((resolve, reject) => {
            const request = {
                URL: saveTransactionUrl,
                method: 'POST',
                data:  transaction 
            };
            
            fetchData(request)            
                .then((response) => {  
                    HandleResponse(response)
                        .then((result) => {
                            resolve(result)
                        })
                })
                .catch((err) => {
                    console.log('Error caught!', err);
                    console.log('Payload that failed', transaction)
                    reject(err);
                });
        })
    }

}

export default TransactionDataAccessor;