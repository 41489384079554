import React from 'react'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import TextInput  from '../components/controls/textInput'
import StaticLabel from '../components/controls/staticLabel'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import LoaderDisplay from '../components/controls/loaderDisplay'
import BooleanDisplay from '../components/controls/booleanDisplay'
import { Consumer } from '../context'
import { withRouter } from 'react-router-dom';
import CheckboxInput from './controls/checkboxInput'
import { formatMoney, sortMethod } from '../Helpers/Formatter'
import { withToastManager, } from 'react-toast-notifications';
import ReactGA from 'react-ga';
import CategoryEditor from './subcomponents/categoryEditor'

class Categories extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            includeInactive: false,
            categoryList: [],
            selectedRowId: null,
            categoryModalVisible: false,
            subcategoryModalVisible: false,
            mergeModalVisible: false,
            newCategoryName: null,
            newCategoryIsIncome: false,
            selectedCategoryId: null,
            mergeToSubcategoryId: null,
        }
    }

    loadData() {
        this.context.getCategories()
            .then((result) => {
                this.setState({
                    categoryList: result,
                    isLoading: false
                })
            });
    }
    
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
        this.loadData()
    }

    

    changeCategoryState(isSubcategory, id, newState) {
        const { toastManager } = this.props;

        const request = {
            deletedFlag: newState,
            id: id,
            isSubcategory: isSubcategory
        }

        this.context.changeCategoryState(request)
            .then((data) => {
                toastManager.add(`The ${isSubcategory ? "subcategory" : "category"} was ${newState ? "deleted" : "restored"} successfully.`, {
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: false,
                    });

                if(isSubcategory===false) {

                    const thisCategory = this.state.categoryList
                        .find((item) => item.id === id)

                    thisCategory.isDeleted = newState;

                    let items = [...this.state.categoryList]
                    items[thisCategory] = thisCategory;

                    this.setState({
                        categoryList: items,
                        isLoading: false
                    })
                }
                else 
                {
                    this.state.categoryList.forEach((category) => {

                        const thisSubCategory = category.subcategories
                            .find((item) => item.id === id)

                        if(thisSubCategory) {

                            thisSubCategory.isDeleted = newState
                            let newSubs = [...category.subcategories]
                            newSubs[thisSubCategory] = thisSubCategory

                            category.subcategories = newSubs
                            
                            let items = [...this.state.categoryList]
                            items[category] = category;

                            this.setState({
                                categoryList: items,
                                isLoading: false
                            })

                            return
                        }
                    })
                }
            })
    }

    handleSaveCategory() {
        if(this.state.newCategoryName) {
            const { toastManager } = this.props;
            const data = {
                name: this.state.newCategoryName,
                id: this.state.selectedCategoryId,
                IncomeCategory: this.state.newCategoryIsIncome
            }
            this.context.saveCategory(data)
                .then((result) => {
                    this.setState({
                        categoryModalVisible: false
                    })

                    toastManager.add(`${this.state.newCategoryName} has been created successfully.`, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                })
        }
    }

    handleSaveSubcategory() {
        if(this.state.newSubcategoryName) {
            const { toastManager } = this.props;
            const data = {
                name: this.state.newSubcategoryName,
                id: this.state.selectedSubcategoryId,
                categoryId: this.state.selectedCategoryId,
            }
            this.context.saveSubcategory(data)
                .then((result) => {
                    this.setState({
                        subcategoryModalVisible: false
                    })

                    toastManager.add(`${this.state.newSubcategoryName} has been created successfully.`, {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                })
        }
    }

    handleMerge() {
        const { toastManager } = this.props;

        if(this.state.selectedSubcategoryId === this.state.mergeToSubcategoryId) {
            toastManager.add(`Unable to merge sub category it's self`, {
                appearance: 'error',
                autoDismiss: true,
                pauseOnHover: false,
            });

            return;
        }

        if(this.state.mergeToSubcategoryId && this.state.selectedSubcategoryId) {
            this.context.mergeSubCategory(this.state.selectedSubcategoryId, this.state.mergeToSubcategoryId)
                .then((result) => {
                    this.setState({
                        mergeModalVisible: false
                    })
                   if(result.ok) {
                        toastManager.add(`Sub catregory has been merged successfully.`, {
                            appearance: 'success',
                            autoDismiss: true,
                            pauseOnHover: false,
                        });
                   } else {
                        toastManager.add(`Failed to merge subcategory. ${result.message}`, {
                            appearance: 'error',
                            autoDismiss: true,
                            pauseOnHover: false,
                        });
                   }
                })
        } else {
            toastManager.add(`Need to select a origin and destination subcategory`, {
                appearance: 'error',
                autoDismiss: true,
                pauseOnHover: false,
            });
        }
    }


    handleChange(property, value) {
        this.setState({
            [property] : value
        })
    }

    showCategoryModal(id, name, incomeCategory) {
        this.setState({
            selectedCategoryId: id,
            categoryModalVisible: true,
            newCategoryName: name,
            newCategoryIsIncome: incomeCategory
        })
    }

    showSubcategoryModal(subcategoryId, categoryId, name) {
        this.setState({
            selectedSubcategoryId: subcategoryId,
            subcategoryModalVisible: true,
            selectedCategoryId: categoryId,
            newSubcategoryName: name
        })
    }

    showMergeModal(subCategoryId, name) {
        this.setState({
            selectedSubcategoryId: subCategoryId,
            mergeModalVisible: true,
            newSubcategoryName: name
        })
    }

    Create() {
       // this.props.history.push(`/schedule/`);
    }
     
    render() {

        const handleClose = () => { this.setState({ categoryModalVisible: false, subcategoryModalVisible: false, mergeModalVisible: false }) };
        const handleShow = () => { this.setState({ categoryModalVisible: true, newCategoryName: null, selectedCategoryId: null,   }) };

        return (
            <React.Fragment>
                <AuthorisedArea showError>
                    <Consumer.Consumer>
                    {value => { 
                        return(
                            <React.Fragment>
                                <div className="borderBoxStyle">
                                    <Row>
                                        <Col md="6">
                                            <CheckboxInput
                                                value={this.state.includeInactive}
                                                help="Show Inactive Categories"
                                                onChange={(value) => this.setState({ includeInactive: value})}
                                                label="Include Inactive"
                                            />

                                        </Col>
                                        <Col md="6" className="text-right"><Button onClick={handleShow}>Create Category</Button></Col>
                                    </Row>
                                </div>
                                {this.state.isLoading && 
                                    <LoaderDisplay />
                                } 
                                {!this.state.isLoading && 
                                    <div className=" top-buffer tableViewStyle">
                                        <Row>
                                            <Col md="12" >
                                                <Row className="tableViewHeaderStyle">  
                                                    <Col md="3">Category</Col>
                                                    <Col md="2">Enabled</Col>
                                                    <Col md="2" className="text-right">Value</Col>
                                                    <Col md="2" className="text-right">Transactions</Col>
                                                </Row>
                                                {value.cacheStore.categories.length === 0 &&
                                                    <div>No categories available</div>
                                                }
                                                {value.cacheStore.categories
                                                    .filter((z) => !z.isDeleted || this.state.includeInactive)
                                                    .sort((a, b) => { return sortMethod(a.name, b.name) })
                                                    .map((item, index) => 
                                                    
                                                        <React.Fragment key={index}>
                                                        <Row onClick={() => this.setState({ selectedRowId: item.id})}  className={`tableViewRowStyle pointable ${this.state.selectedRowId === item.id ? 'selectedRow' : ''}`} >
                                                            <Col md="3">
                                                                <span> <strong>{item.name}</strong></span>
                                                            </Col>
                                                            <Col md="2">
                                                                <BooleanDisplay value={!item.isDeleted} />
                                                            </Col>
                                                                
                                                                <Col md="2"></Col>
                                                                <Col md="5" className="text-right">{this.state.selectedRowId === item.id &&
                                                                    <React.Fragment>
                                                                        <a 
                                                                            href="#" 
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); // Prevents navigation
                                                                                this.showCategoryModal(item.id, item.name, item.incomeCategory);
                                                                            }} 
                                                                            className="selectedRowToolbar"
                                                                            >
                                                                            [ Modify ]
                                                                        </a>
                                                                        {!item.isDeleted &&
                                                                            <React.Fragment>
                                                                                <a 
                                                                                    href="#" 
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault(); // Prevents navigation
                                                                                        this.changeCategoryState(false, item.id, !item.isDeleted);
                                                                                    }} 
                                                                                    className="warningText selectedRowToolbar"
                                                                                    >
                                                                                    [ Delete ]
                                                                                </a>

                                                                                <a 
                                                                                    href="#" 
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault(); // Prevents navigation
                                                                                        this.showSubcategoryModal(null, item.id, null);
                                                                                    }} 
                                                                                    className="selectedRowToolbar"
                                                                                    >
                                                                                    [ Create Subcategory ]
                                                                                </a>
                                                                            </React.Fragment>
                                                                        }
                                                                        {item.isDeleted && 
                                                                            <a 
                                                                                href="#" 
                                                                                onClick={(e) => {
                                                                                    e.preventDefault(); // Prevents navigation
                                                                                    this.changeCategoryState(false, item.id, !item.isDeleted);
                                                                                }} 
                                                                                className="selectedRowToolbar"
                                                                                >
                                                                                [ Restore ]
                                                                                </a>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                                </Col>
                                                            </Row>
                                                            
                                                            <React.Fragment>
                                                                {item.subcategories
                                                                    .filter((sub) => !sub.isDeleted || this.state.includeInactive)
                                                                    .sort((a, b) => { return sortMethod(a.name, b.name) })
                                                                    .map((sub, index2) => 
                                                                        <Row index={index2} key={sub.id} onClick={() => this.setState({ selectedRowId: sub.id})}  className={`tableViewRowStyle pointable ${this.state.selectedRowId === sub.id ? 'selectedRow' : ''}`} >
                                                                            <Col md="3" className="indent">{sub.name}</Col>
                                                                            <Col md="2"><BooleanDisplay value={!sub.isDeleted} /></Col>
                                                                            <Col md="2" className="text-right">{formatMoney(sub.amount)}</Col>
                                                                            <Col md="2" className="text-right">{sub.usages}</Col>
                                                                            <Col md="3" className="text-right">{this.state.selectedRowId === sub.id &&
                                                                                <React.Fragment>
                                                                                    <a 
                                                                                        href="#" 
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault(); // Prevents navigation
                                                                                            this.showSubcategoryModal(sub.id, item.id, sub.name);
                                                                                        }} 
                                                                                        className="selectedRowToolbar"
                                                                                        >
                                                                                        [ Modify ]
                                                                                    </a>
                                                                                    {!sub.isDeleted && sub.usages === 0 &&
                                                                                        <a 
                                                                                            href="#" 
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault(); // Prevents navigation
                                                                                                this.changeCategoryState(true, sub.id, !sub.isDeleted);
                                                                                            }} 
                                                                                            className="selectedRowToolbar warningText"
                                                                                            >
                                                                                            [ Delete ]
                                                                                        </a>
                                                                                    
                                                                                    }
                                                                                    {!sub.isDeleted && sub.usages > 0 &&
                                                                                        <a 
                                                                                            href="#" 
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault(); // Prevents navigation
                                                                                                this.showMergeModal(sub.id, sub.name);
                                                                                            }} 
                                                                                            className="selectedRowToolbar warningText"
                                                                                            >
                                                                                            [ Merge ]
                                                                                        </a>
                                                                                    
                                                                                    }
                                                                                    {sub.isDeleted && 
                                                                                        <a 
                                                                                            href="#" 
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault(); // Prevents navigation
                                                                                                this.changeCategoryState(true, sub.id, !sub.isDeleted);
                                                                                            }} 
                                                                                            className="selectedRowToolbar"
                                                                                            >
                                                                                            [ Restore ]
                                                                                        </a>

                                                                                    }
                                                                                </React.Fragment>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                )}
                                                                </React.Fragment>
                                                        
                                                        </React.Fragment> 
                                                    
                                                )}
                                            </Col>
                                        </Row>
                                        </div>
                                    }
                                    
                                                    </React.Fragment>
                                        )}
                                }
                                </Consumer.Consumer>
                                <Modal 
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={this.state.categoryModalVisible} 
                                    onHide={handleClose} 
                                    animation={true}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Category</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col md="6">
                                                <TextInput 
                                                    value={this.state.newCategoryName}
                                                    label="Category Name"
                                                    onChange={(value) => this.handleChange("newCategoryName", value)}
                                                    help="The name of the category"
                                                />
                                            </Col>
                                            <Col md="6">
                                                <CheckboxInput 
                                                    value={this.state.newCategoryIsIncome}
                                                    label="Income Category"
                                                    onChange={(value) => this.handleChange("newCategoryIsIncome", value)}
                                                    help="Is the category an Income category"
                                                />
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={() => this.handleSaveCategory()}>
                                            Save Changes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>


                                <Modal 
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={this.state.mergeModalVisible} 
                                    onHide={handleClose} 
                                    animation={true}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Subcateogry Merge</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col md="12">
                                                <CategoryEditor 
                                                    onChange = {(value) => this.handleChange("mergeToSubcategoryId", value)}
                                                    value = {this.state.mergeToSubcategoryId}
                                                    isViewMode={this.state.isViewMode}
                                                />
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={() => this.handleMerge()}>
                                            Merge
                                        </Button>
                                    </Modal.Footer>
                                </Modal>







                                <Modal 
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={this.state.subcategoryModalVisible} 
                                    onHide={handleClose} 
                                    animation={true}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Subcategory</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col md="6">
                                                <StaticLabel 
                                                    value="Test"
                                                    label="Selected Category"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <TextInput 
                                                    value={this.state.newSubcategoryName}
                                                    label="Subcategory Name"
                                                    onChange={(value) => this.handleChange("newSubcategoryName", value)}
                                                    help="The name of the sub category"
                                                />
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={() => this.handleSaveSubcategory()}>
                                            Save Changes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                </AuthorisedArea>
                            </React.Fragment>
        )
    }


}

Categories.contextType = Consumer
export default withToastManager(withRouter(Categories))