import React from 'react'
import AuthorisedArea from '../Helpers/AuthorisedArea'
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'
import { Consumer } from  '../context'
import { withToastManager, } from 'react-toast-notifications';
import LoaderDisplay from './controls/loaderDisplay';
import { DataTypes } from '../Constants'
import Button from './controls/button';
import { ButtonIcons, TransactionTypes } from '../Constants';
import TransactionsRow from './transactionsRow'

import TextInput from './controls/textInput'
import CheckboxInput from './controls/checkboxInput';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import ReactGA from 'react-ga';



class Tag extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      isLoading: false,
      isCreating: true,
      tag: {
        id: null,
        name: null,
        deactivated: false
      },
      transactions: [],
      schedules: []
    }

    this.saveClick = this.saveClick.bind(this)
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    if(this.props.match.params.id) {
      this.loadTag()
    }
  }

  loadTag() {
    const selectedId = this.props.match.params.id

    this.setState({ isLoading : true, isCreating: false })
    this.context.getTag(selectedId)
      .then((tag) => {
        this.setState({
          tag: tag.tag,
          transactions: tag.transactions,
          schedules: tag.schedules,
          isLoading: false
        })
      }) 
  } 

  onSelect(transactionId) {
    this.props.history.push(`/transaction/${transactionId}`);
  }

  saveClick() {

    const data = this.props

    const { toastManager } = this.props;
    this.context.saveTag(this.state.tag)
        .then((result) => {    
            if(result.ok) {
            this.props.history.goBack();
            toastManager.add(`${this.state.tag.name}, Saved Successfully.`, {
                appearance: 'success',
                autoDismiss: true,
                pauseOnHover: false,
                });
            }
            else {
                toastManager.add(result.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: false,
                  });
                  return        
            }
        })

  }

  handleChange(fieldName, value) {
    var tag = this.state.tag;
    tag[fieldName] = value;

    this.setState({
      tag,
    })
  }

  render() {

    if(this.state.isLoading) {
      return <LoaderDisplay />
    }
    const buttonBarStyle = {
      display: "flex",
      justifyContent: "flex-end"
    }

    const buttonSpacingStyle = {
      padding: "4px",
      float: "left"
    }

    const itemStyle = {
      cursor: "pointer"
    }

    const transactions = this.state.transactions;

    let total = 0;

    // Get the category breakdown,
    const totalsByCategorySubcategory = Object.values(transactions.reduce((acc, transaction) => {
      const key = `${transaction.categoryName}: ${transaction.subCategoryName}`;
  
      if (!acc[key]) {
          acc[key] = { Description: key, Amount: 0 };
      }

      const typeMultiplier = transaction.transactionTypeId === TransactionTypes.PAYMENT ? -1 : transaction.transactionTypeId === TransactionTypes.DEPOSIT ? 1 : 0;
  
      acc[key].Amount += transaction.amount * typeMultiplier;

      // Add to the total.
      total += transaction.amount * typeMultiplier;
  
      return acc;
    }, {}));





    return (
      <React.Fragment >
        <AuthorisedArea showError>
          <div className="borderBoxStyle">  
            <Row>
              <Col>
                <Row>
                  <Col>
                      <h5>Tag</h5>
                      <hr />
                  </Col>
                </Row>
          
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="6" xs={8}>
                        <TextInput
                            label="Name"
                            help="Tag Name"
                            onChange={(value) => this.handleChange("name", value)}
                            value={this.state.tag?.name}
                            dataType={DataTypes.TEXT}
                            isViewMode={this.state.isViewMode}
                        />
                      </Col>
                      <Col md="6" xs={4}>
                        <CheckboxInput
                            value={!this.state.tag?.deactivated}
                            label="Currently Active"
                            onChange={(value) => this.handleChange('deactivated', value)}
                            help="Tag can be used"
                            />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Button  
                          isBusy={this.state.isBusy}
                          onClick={this.saveClick} 
                          text="Save"
                          variant="primary"
                          style={buttonSpacingStyle}
                          icon={ButtonIcons.SAVE}
                        />
                      </Col> 
                    </Row>
                  </Col>

                  {this.state.isCreating === false && 
                    <Col md="6">
                      <Row>
                        <Col>
                          <h5>Category Breakdown</h5>
                        </Col>
                      </Row>
                        {totalsByCategorySubcategory.length > 0 ? (
                            totalsByCategorySubcategory.map((x, index) => (
                              <Row key={index} className="tableViewRowStyle" style={itemStyle}>
                                <Col md="8" xs={8}>
                                  {x.Description}
                                </Col>
                                <Col md={4} xs={4} className="text-right">
                                  {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.abs(x.Amount))}  
                                </Col>
                              </Row>
                            ))
                          ) : (
                            <Row>
                              <Col>No transactions available</Col>
                            </Row>
                          )
                        }
                      <Row className="tableViewRowStyle" style={itemStyle}>
                      
                        <Col md="8" xs={8} >
                        <strong>Total:</strong>
                        </Col>
                        <Col md={4} xs={4} className="text-right">
                          <strong>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Math.abs(total))} </strong> 
                        </Col>
                      </Row>


                    </Col>
                }
                </Row>


                {this.state.tag?.id &&
                  <Row className="top-buffer">
                    <Col md="12" xs="12">
                      {this.state.tag && this.state.transactions &&
                        <div className="borderBoxStyle">  
                          {this.state.transactions.map((item, index) => 

                          <Row key={index} onClick={() => this.onSelect(item.id)} className="tableViewRowStyle" style={itemStyle}>
                              <TransactionsRow transaction={item}/>
                          </Row>
                          )}
                        </div>
                      }
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
          </div>   
        </AuthorisedArea>
      </React.Fragment>
    )
  }
}

Tag.contextType = Consumer;
export default withToastManager(withRouter(Tag));