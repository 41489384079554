import fetchData from '../Helpers/FetchData'
import Server from '../Constants'
import { HandleResponse } from './ResponseHandler' 

const accountUrl = `${Server.ApiURL}/api/categories`;

class CategoryDataAcessor {

    static getCategories() {
        return new Promise((resolve, reject) => {
          const request = {
            URL: accountUrl,
            method: 'GET'
          };
    
          fetchData(request)            
          .then((response) => {  
              HandleResponse(response)
                  .then((result) => {
                      resolve(result)
              })
          })
          .catch((err) => {
              console.log('Error caught!', err);
              reject(err);
          });
        })
    }

    static mergeSubCategory(fromSubCategoryId, toSubCategoryId) {
        return new Promise((resolve, reject) => {
            const request = {
                URL:`${Server.ApiURL}/api/subcategory/${fromSubCategoryId}/merge/${toSubCategoryId}`,
                method: 'POST'
              };

              fetchData(request)            
              .then((response) => {  
                  HandleResponse(response)
                      .then((result) => {
                          resolve(result)
                  })
              })
              .catch((err) => {
                  console.log('Error caught!', err);
                  reject(err);
              });
            })
    }

    static saveCategory(data) {
        return new Promise((resolve, reject) => {
            const request = {
                URL:`${Server.ApiURL}/api/category`,
                method: 'POST',
                data: data
              };

              fetchData(request)            
              .then((response) => {  
                  HandleResponse(response)
                      .then((result) => {
                          resolve(result)
                  })
              })
              .catch((err) => {
                  console.log('Error caught!', err);
                  reject(err);
              });
            })
    }

    static saveSubcategory(data) {
        return new Promise((resolve, reject) => {
            const request = {
                URL:`${Server.ApiURL}/api/subcategory`,
                method: 'POST',
                data: data
              };

              fetchData(request)            
              .then((response) => {  
                  HandleResponse(response)
                      .then((result) => {
                          resolve(result)
                  })
              })
              .catch((err) => {
                  console.log('Error caught!', err);
                  reject(err);
              });
            })
    }

    static changeCategoryState(data) {
        return new Promise((resolve, reject) => {
            const request = {
              URL:`${Server.ApiURL}/api/category`,
              method: 'PATCH',
              data: data
            };
      
            fetchData(request)            
            .then((response) => {  
                HandleResponse(response)
                    .then((result) => {
                        resolve(result)
                })
            })
            .catch((err) => {
                console.log('Error caught!', err);
                reject(err);
            });
        })
    }
}

export default CategoryDataAcessor;