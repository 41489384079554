import React from 'react';
import Auth from './Authentication';
import BudgetDataAcessor from './api/BudgetDataAccessor'
import AccountDataAccessor from './api/AccountsDataAccessor'
import CategoryDataAcessor from './api/CategoryDataAccessor'
import TransactionDataAccessor from './api/TransactionDataAccessor';
import UserDataAccessor from './api/UserDataAccessor'
import ReportDataAccessor from './api/ReportDataAccessor'
import ScheduleDataAccessor from './api/ScheduleDataAccessor'
import AuthenticationDataAccessor from './api/AuthenticationDataAccessor'
import InvestmentDataAccessor from './api/InvestmentDataAccessor'
import TagDataAccessor from './api/TagDataAccessor';

const Context = React.createContext();

export class Provider extends React.Component {

    state = {

        ResetAll: () => {
            return new Promise(() => {

                this.state.clearCache('allAccountsList')
                this.state.clearCache('budgets')
                this.state.clearCache('categories')
                this.state.clearCache('forecastBalanceData')
                this.state.clearCache('historicBalanceData')
                this.state.clearCache('recentTransactions')
                this.state.clearCache('transactions')
                this.state.clearCache('upcomingTransactions')
                this.state.clearCache('forecastBalanceData')
                this.state.clearCache('investments')
                this.state.clearCache('investmentBalance')
                this.state.clearCache('tags')
                this.setState({
                    _defaultAccountId: null,
                    lastSelectedDate: new Date(),
                    user: { authStatus: false }
                })

            })
           
        },
// ***********************************************************
// User and Authentication
//************************************************************
        
        // Variables.
        user: {
             authStatus : Auth.isAuthenticated(),
             displayName: Auth.firstname()
         },

         validateEmail: (id) => {
             return new Promise((resolve) => {
                AuthenticationDataAccessor.validateEmailAddress(id)
                    .then((result) => {
                        resolve(result)
                    })
             })
           
         },

         lastSaveGuid: null,

         cacheStore: {

            allAccountsList: [],
            allAccountsListLoaded: false,

            budgets: [],
            budgetsLoaded: false,

            categoriesLoaded: false,
            categories: [],

            forecastBalanceDataLoaded: false,
            forecastBalanceData: [],

            historicBalanceDataLoaded: false,
            historicBalanceData: [],

            recentTransactions: [],
            recentTransactionsLoaded: false,

            transactions: [],
            transactionsLoaded: false,

            upcomingTransactions: [],
            upcomingTransactionsLoaded: false,

            timezones: [],
            timezonesLoaded: false,

            investments: [],
            investmentsLoaded: false,

            investmentBalance: 0,
            investmentBalanceLoaded: false,

            tags: [],
            tagsLoaded: false


         },

         setCache: (cacheName, value) => {
            let store = this.state.cacheStore;
            store[cacheName] = value;
            store[`${cacheName}Loaded`] = true;
            this.setState({
                cacheStore : store
            })
         },

         clearCache: (cacheName) => {
            let store = this.state.cacheStore;
            store[cacheName] = [];
            store[`${cacheName}Loaded`] = false;
            this.setState({
                cacheStore : store
            })
         },

         _lastPollValue: null,
         _defaultAccountId: null,
         lastSelectedDate: new Date(),

         setLastSelectedDate: (newDate) => { 
             this.setState({ lastSelectedDate: newDate })
         },

         pollForChanges: () => {
            return new Promise((resolve) => {
                if(this.state.isAuthenticated()) {
                UserDataAccessor.pollChanges()
                    .then((result) => {
                        if(result.data !== this.state._lastPollValue)
                        {
                            this.setState({
                                _lastPollValue: result.data
                            }, () => {

                                this.state.clearCache('historicBalanceData')
                                this.state.clearCache('forecastBalanceData')
                                this.state.clearCache('recentTransactions')
                                this.state.clearCache('upcomingTransactions')

                                this.state.getDashboardHistoricBalanceReport(14)
                                this.state.getDashboardForcastBalanceReport()
                                this.state.getRecentTransactions(true, 5)
                                this.state.getAllAccounts(true);
                            })
                        }
                    })
                }
                resolve()
            })
         },

         getDefaultAccount: () => {
             return new Promise((resolve) => {
                if(this.state._defaultAccountId)
                {   
                    resolve(this.state._defaultAccountId)
                } 
                else 
                {
                    this.setState({
                        _defaultAccountId: Auth.defaultAccount(),
                    }, () => { 
                        resolve(this.state._defaultAccountId) 
                    })
                }
             })
         },

         setDefaultAccount: (defaultAccountId) => {
                this.setState({
                    _defaultAccountId: defaultAccountId
                }, () => {
                    this.state.clearCache('historicBalanceData')
                    this.state.clearCache('forecastBalanceData')
                })
         },

         // Methods
        changeAuthenticated: (newState) => {
            this.state.ResetAll()
                .then(() => {
                });

            if(newState === false) {
               
                Auth.logout();
                return
            }
            let user = this.state.user

            this.state.getDefaultAccount()
                .then((result ) => {
                    user.defaultAccount = result
                    user.displayName = Auth.firstname()
                    user.authStatus = Auth.isAuthenticated()
                    this.setState ({ 
                        user: user, 
                    });
                })
        },

        logout: () => {

            this.state.clearCache('historicBalanceData')
            this.state.clearCache('forecastBalanceData')

            this.setState( {
                budgets: [],
                budgetsLoaded: false,

                investments: [],
                investmentsLoaded: false,

                tags: [],
                tagsLoaded: false,

                allAccountsList: [],
                allAccountsListLoaded: false,

                simpleAccountList: [],
                quickAccountList: [],
                recentTransactions: [],
                upcomingTransactions: [],
                transactions: [],

                
                bankAccounts: [],
                bankAccountsLoaded: false,
                
                categories: [],
                categoriesLoaded: false,
                recentTransactionsLoaded: false,

                investmentBalance: 0,
                investmentBalanceLoaded: false,

                _defaultAccountId: null,
                
                user: {
                    authStatus: false,
                    defaultAccount: null,
                    displayName: null
                },
            })
            Auth.logout();
        },

        isAuthenticated: () => {
            return this.state.user.authStatus
        },

        getUser: () => {
            return new Promise((resolve) => {
                UserDataAccessor.getUser() 
                    .then((data) => {
                           resolve(data)
                    })
                })
        },

        registerUser: (data) => {
            return new Promise((resolve, reject) => {
                UserDataAccessor.registerUser(data)
                  .then((result) => {
                    resolve(result)
                  })
            })
        },


        getTimezones: () => {
            return new Promise((resolve) => {
                if(!this.state.cacheStore.timezonesLoaded) {
                    UserDataAccessor.getTimezones()
                        .then((data) => {
                            this.state.setCache('timezones', data.data)
                            resolve(data.data)
                        })
                } else {
                    resolve(this.state.cacheStore.timezones)
                }
                
            })
        },

        saveUser: (data) => {
            this.state.setDefaultAccount(data.defaultAccountId)

            return new Promise((resolve, reject) => {
                UserDataAccessor.saveUser(data)
                    .then((result) => {
                        resolve(result)
                    })
            })
        },

        changePassword: (data) => {
            return new Promise((resolve, reject) => {
                UserDataAccessor.changePassword(data)
                    .then((result) => {
                        resolve(result)
                    })
            })
        },

// ***********************************************************
// Investments
//************************************************************
        getInvestments: (forceUpdate) => {
            return new Promise((resolve) => {
                if(!this.state.cacheStore.investmentsLoaded || forceUpdate) {
                    InvestmentDataAccessor.getInvestments()
                    .then((result) => {
                        this.state.setCache('investments', result.data)
                        var sum = result.data.reduce((accumalator, current) => accumalator + current.volumeOwned * current.investmentType.price, 0)
                        this.state.setCache('investmentBalance', sum)
                        resolve(result.data)
                    })
                }
                else
                {
                    resolve(this.state.cacheStore.investments)
                }
            })
        },
        getInvestment: (id) => {
            return new Promise((resolve) => {
               
                    InvestmentDataAccessor.getInvestment(id)
                    .then((result) => {
                        resolve(result.data)
                    })
                }
            )
        },

        saveInvestment: (data) => {
            return new Promise((resolve) => {
                InvestmentDataAccessor.saveInvestment(data)
                    .then((result) => {
                        this.state.getInvestments(true)
                        resolve(result)
                    })
            })
        },

        getInvestmentChart: (historicDays) => {
            return new Promise((resolve) => {
               
                    InvestmentDataAccessor.getChart(historicDays)
                    .then((result) => {
                        resolve(result)
                    })
                }
            )
        },



// ***********************************************************
// Budgets
//************************************************************
         
        getBudgets: (forceUpdate) => {
            return new Promise((resolve) => {
                if(!this.state.cacheStore.budgetsLoaded || forceUpdate) {
                    BudgetDataAcessor.getBudgets()  
                    .then((result) => {
                        this.state.setCache('budgets', result.data)
                        resolve(result.data)
                    })
                } 
                else 
                {
                    resolve(this.state.cacheStore.budgets)
                }
            })
        },


        getBudget: (id) => {
            return new Promise((resolve) => {
                BudgetDataAcessor.getBudget(id)
                    .then((result) => {                       
                        resolve(result)
                    })
            })
        },

        getBudgetTransactions: (budgetId, budgetNumber) => {
            return new Promise((resolve) => {
                BudgetDataAcessor.getBudgetTransactions(budgetId, budgetNumber)
                    .then((result) => {
                        resolve(result)
                    })
            })
        },

        saveBudget: (data) => {
            return new Promise((resolve) => {
                BudgetDataAcessor.saveBudget(data)
                    .then((result) => {
                        this.state.getBudgets(true)
                        resolve(result)
                    })
            })
        },

        deleteBudget: (id) => {
            return new Promise((resolve) => {
                BudgetDataAcessor.deleteBudget(id)
                    .then((result) => {
                        this.state.getBudgets(true)
                        resolve(result.data)
                    })
            })
        },



// ***********************************************************
// Tags
//************************************************************

        // Methods
        saveTag: (payload) => {
            return new Promise((resolve, reject) => {
                TagDataAccessor.Save(payload)
                    .then((result) => {
                        this.state.getTags(true)
                        resolve(result)
                    })
                
            });
        },
        getTags: (forceUpdate) => {
            return new Promise((resolve, reject) => {
                if(!this.state.cacheStore.tagsLoaded || forceUpdate) {
                    TagDataAccessor.getTags()
                        .then((result) => {
                            this.state.setCache('tags', result)
                            resolve(result)
                        })
                } else {
                    resolve(this.state.cacheStore.tags);
                }
            });
        },
        getTag: (tagId) => {
            return new Promise((resolve, reject) => {
                TagDataAccessor.getTag(tagId)
                        .then((result) => {
                            resolve(result)
                        })

            });
        },
        getDetailedTags: (fromDate, toDate) => {
            return new Promise((resolve, reject) => {
                    const payload = { fromDate, toDate };
                    TagDataAccessor.getDetailedTags(payload)
                        .then((result) => {
                            resolve(result)
                        })
            });
        },



// ***********************************************************
// Accounts
//************************************************************
         
        // Variables.
        simpleAccountList: [],
        quickAccountList: [],
        quickAccountListLoaded: false,

        // Methods
        getAllAccounts: (forceUpdate) => {
            return new Promise((resolve, reject) => {
                if(!this.state.cacheStore.allAccountsListLoaded || forceUpdate) {
                    AccountDataAccessor.getAllAccounts()
                        .then((result) => {
                            this.state.setCache('allAccountsList', result)
                            resolve(result)
                        })
                } else {
                    resolve(this.state.cacheStore.allAccountsList);
                }
            });
        },

        
        getAccount: (id) => {
            return new Promise((resolve) => {
                AccountDataAccessor.getAccount(id)
                    .then((result) => {
                        resolve(result.data)
                    }) 
            })
        },


        saveAccount: (account) => {
            return new Promise((resolve, reject) => {
                AccountDataAccessor.saveAccount(account)
                    .then((result) => {
                        this.state.getAllAccounts(true)
                            .then((data => {
                                this.state.setCache('allAccountsList', data) 
                            }))
                        // TODO: Need to update all the transactions currently cached.
                        resolve(result)
                    })
                .catch((err) => {
                    reject(err)
                })
            })
        },

        deleteAccount: (id) => {
            return new Promise((resolve, reject) => {
                AccountDataAccessor.deleteAccount(id)
                    .then((result) => {
                        this.state.getAllAccounts(true)
                            .then((data => {
                                this.state.setCache('allAccountsList', data) 
                            }))
                        // TODO: Need to update all the transactions currently cached.
                        resolve(result)
                    })
                    .catch((err) => {
                        reject(err)
                    })
                })
        },

        updateSimpleAccountList: (data) => {
            this.setState({ simpleAccountList: data })
        },

        getQuickAccounts: () => {
            return new Promise((resolve) => {
                this.state.getAllAccounts(false)
                    .then((accounts) => {
                        const quicks = accounts.filter((item) => item.canQuickTransaction)
                            .map((account) => {
                                return account
                            })
                        resolve(quicks)
                    })
                })
        },

        saveQuickTransaction: (transaction) => {
            return new Promise((resolve) => {
                this.state.getDefaultAccount()
                    .then((defaultAccountId) => {

                        // Inject current default account.
                        const data = {...transaction, debitAccountId :defaultAccountId}
                            // perform the same...
                            TransactionDataAccessor.saveQuickTransaction(data)
                                .then((data)=> {
                                    // Handle good result
                                    if(data.ok) {
                                        this.state.clearCache('historicBalanceData')
                                        this.state.clearCache('forecastBalanceData')
                                        this.state.clearCache('recentTransactions')
                                        this.state.clearCache('upcomingTransactions')

                                        var historyPromise = this.state.getDashboardHistoricBalanceReport(14)
                                        var forecastPromise = this.state.getDashboardForcastBalanceReport()
                                        var transPromise = this.state.getRecentTransactions(true, 5)
                                        var accountsPromise = this.state.getAllAccounts(true);

                                        Promise.all([historyPromise, forecastPromise, transPromise, accountsPromise])
                                        .then(() => {
                                            resolve(data)  
                                        })
                                    } 
                                    else // handle bad result
                                    {
                                        resolve(data)
                                    }
                                })
                        })
                })
        },
        

// ***********************************************************
// Category and Sub Categories
//************************************************************
   
        // Methods
        getCategories: (forceUpdate, inludeDeleted=false) => {
            return new Promise((resolve) => {
                if(!this.state.cacheStore.categoriesLoaded || forceUpdate) {
                    CategoryDataAcessor.getCategories()
                        .then((result) => {
                            this.state.setCache('categories', result.data)
                            resolve(result.data)
                        })
                } else {
                    resolve(this.state.cacheStore.categories)
                }
            })
        },

        saveCategory: (data) => {
            return new Promise((resolve) => {
                CategoryDataAcessor.saveCategory(data)
                    .then((result) => {
                        this.state.getCategories(true)
                            .then((data) => {
                                this.state.setCache('categories', data)
                                resolve(data);
                            })
                        
                    })
            })
        },

        mergeSubCategory: (oldSubCategoryId, newSubCategoryId) => {
            return new Promise((resolve) => {
                CategoryDataAcessor.mergeSubCategory(oldSubCategoryId, newSubCategoryId)
                    .then((result) => {
                        this.state.getCategories(true)
                            .then((data) => {
                                this.state.setCache('categories', data)
                                resolve(result);
                            })
                        
                    })
            })
        },

        
        saveSubcategory: (data) => {
            return new Promise((resolve) => {
                CategoryDataAcessor.saveSubcategory(data)
                    .then((result) => {
                        this.state.getCategories(true)
                            .then((data) => {
                                this.state.setCache('categories', data)
                                resolve(result)
                            })
                        
                    })
            })
        },

        changeCategoryState: (newState) => {
            return new Promise((resolve) => {
                CategoryDataAcessor.changeCategoryState(newState)
                    .then((result) => {
                        this.state.getCategories(true)
                        .then((data) => {
                            this.state.setCache('categories', data)
                            resolve(result)
                        })
                        
                        
                    })
            })
        },

// ***********************************************************
// Scheduled Transactions
//************************************************************
   
        // Methods.
        getSchedule: (id) => {
            
            return new Promise((resolve) => {
                ScheduleDataAccessor.getSchedule(id)
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((err) => {
                        console.log("context: Error getting schedule", err)
                    })
            })
        },

        saveSchedule: (schedule) => {
            return new Promise((resolve) => {
                ScheduleDataAccessor.saveSchedule(schedule)
                    .then((result) => {
                        if(result.ok) {
                            this.state.clearCache('forecastBalanceData')
                            this.state.clearCache('upcomingTransactions')
                        }
                        resolve(result)
                    })
                    .catch((e) => {
                        console.log("Error saving schedule.", e)
                    })
            })
        },

        deleteSchedule: (id) => {
            return new Promise((resolve) => {
                ScheduleDataAccessor.deleteSchedule(id)
                    .then((result) => {
                        if(result.ok) {
                            this.state.clearCache('forecastBalanceData')
                            this.state.clearCache('upcomingTransactions')
                        }
                        resolve(result)
                    })
                    .catch((e) => {
                        console.log("Error deleting schedule.", e)
                    })
            })
        }, 

        // This is a method that simply causes a variation based on an Skip request
        skipScheduledTransaction: (scheduleId, paymentNumber) => {

            const data = {
                scheduleId,
                paymentNumber,
                skipIt: true,
            }

            return this.state.scheduleVariation(data)

        },

        // This is a method that simply causes a variation based on an UnSkip request
        unSkipScheduledTransaction: (scheduleId, paymentNumber) => {

            const data = {
                scheduleId,
                paymentNumber,
                skipIt: false,
            }

            return this.state.scheduleVariation(data)

        },

        payScheduledTransaction: (scheduleId, paymentNumber) => {
            return new Promise((resolve) => {
                ScheduleDataAccessor.pay(scheduleId, paymentNumber)
                    .then((result) => {
                        if(result.ok) {
                            this.state.clearCache('historicBalanceData')
                            this.state.clearCache('recentTransactions')
                            this.state.clearCache('upcomingTransactions')

                            this.state.getDashboardHistoricBalanceReport(14)
                            this.state.getDashboardForcastBalanceReport()
                            this.state.getRecentTransactions(true, 5)
                            this.state.getAllAccounts(true);
                        }
                        resolve(result)
                    })
            })
        },

        scheduleVariation: (data) => {
            return new Promise((resolve) => {
                ScheduleDataAccessor.variation(data)
                    .then((result) => {
                        if(result.ok)
                        {
                            this.state.clearCache('upcomingTransactions')
                            this.state.clearCache('forecastBalanceData')
                            this.state.getDashboardForcastBalanceReport()
                        }
                        resolve(result)
                    })
            })
        },

        deleteScheduleVariation: (variationId) => {
            return new Promise((resolve) => {
                ScheduleDataAccessor.deleteVariation(variationId)
                .then((result) => {
                    if(result.ok) {
                        this.state.clearCache('upcomingTransactions')
                        this.state.clearCache('forecastBalanceData')
                        this.state.getDashboardForcastBalanceReport()
                    }
                    resolve(result)
                })
            })
        },

        scheduleCalendar: (year, month) => {
            return new Promise((resolve) => {
                ScheduleDataAccessor.calendar(year, month)
                    .then((result) => {
                        resolve(result)
                    })
            })

        },

// ***********************************************************
// Transactions
//************************************************************
   
        transactionHistoricDays: -1,

        // Methods.
        saveTransaction: (transaction) => {
            return new Promise((resolve) => {
                TransactionDataAccessor.saveTransaction(transaction)
                    .then((result) => {
                        if(result.ok) {
                            this.state.getAllAccounts(true); // Because we saved a transaction. Accounts will have been effected.
                            this.state.clearCache('forecastBalanceData')
                            this.state.clearCache('historicBalanceData')
                            this.state.clearCache('recentTransactions')
                            this.state.clearCache('upcomingTransactions')
                            
                            this.state.getDashboardHistoricBalanceReport(14)
                            this.state.getDashboardForcastBalanceReport()
                            this.state.getRecentTransactions(true, 5)
                            this.state.getBudgets(true)
                            
                            if(transaction.transactionLines.filter((item) => item.budgetId)) {
                                // a transaction line has a budget.
                                this.state.getBudgets(); // Because we saved a transaction. Budgets may have been effected.
                            }     
                        }      
                        resolve(result);        
                    })
                    .catch((e) => {
                        console.log("Error captured while saving transaction.", e)
                        resolve({success: false, message: e});
                    })
                })
        },

        addAttachment: (id, formData) => {
            return new Promise((resolve) => {
                TransactionDataAccessor.addAttachment(id, formData)
                    .then((result) => {
                        resolve(result)
                    })
            })

        },

        bulkUpdateTransactions: (request) => {

            return new Promise((resolve) => {
                TransactionDataAccessor.bulkUpdateTransactions(request)
                .then((result) => {
                    resolve(result);
                })
            })
        },
        
        transactionSearch: (searchRequest) => {

            return new Promise((resolve) => {
                TransactionDataAccessor.search(searchRequest)
                    .then((result) => { 
                        resolve(result)
                    })
            })
        },

        deleteTransaction: (id) => {
            return new Promise((resolve) => {
                TransactionDataAccessor.deleteTransaction(id)
                    .then((result) => {
                        resolve({success: true, message: "saveTransaction OK"});
                        this.state.clearCache('forecastBalanceData')
                        this.state.clearCache('historicBalanceData')
                        this.state.clearCache('budgets')
                        this.state.clearCache('recentTransactions')
                        this.state.clearCache('upcomingTransactions')

                        this.state.getAllAccounts(true); // Because we saved a transaction. Accounts will have been effected.
                        this.state.getRecentTransactions(true, 5);
                        this.state.getBudgets(); // Because we saved a transaction. Budgets may have been effected.
                              
                    })
            })
        },

        matchTransactions: (accountId, fileData) => {
            return new Promise((resolve, reject) => { 

                TransactionDataAccessor.matchTransactions(accountId, fileData)
                    .then((data) => resolve(data))

            })
        },

        getRecentTransactions: (forceUpdate, historicDaysFromNow) => 
        {
            return new Promise((resolve, reject) => {

                if(!this.state.cacheStore.recentTransactionsLoaded || forceUpdate) {
                    const requestObject = {
                        limitResultCount: historicDaysFromNow
                    }
                    TransactionDataAccessor.getTransactions(requestObject)
                        .then((data) => {
                            this.state.setCache('recentTransactions', data.data)
                            resolve(data.data) 
                        })
                } else {
                    resolve(this.state.cacheStore.recentTransactions)
                } 
                    
            })
        },


        getUpcoming: (filter) => {
            return new Promise((resolve) => {
                if(this.state.cacheStore.upcomingTransactionsLoaded) {
                    resolve(this.state.cacheStore.upcomingTransactions)
                }
                else
                {
                    
                    ScheduleDataAccessor.getUpcoming(filter)
                        .then((data) => {
                            this.state.setCache('upcomingTransactions', data.data)
                            resolve(data.data)
                        })
                }
       
            })
        },
        


        getTransactions: (filter) => 
        {
            return new Promise((resolve) => {
                TransactionDataAccessor.getTransactions(filter)
                    .then((data) => {
                        this.state.setCache('transactions', data.data)
                        resolve(data.data);

                })
            })
        },


        getTransaction: (transactionId) => {
            return new Promise((resolve) => {
                TransactionDataAccessor.getTransaction(transactionId)
                    .then((result) => {
                        resolve(result)
                    })
                })
        },

        getTransactionForSchedule: (scheduleId, paymentNumber) =>{
            return new Promise((resolve) => {
                TransactionDataAccessor.getTransactionForSchedule(scheduleId, paymentNumber)
                    .then((data) => {
                        resolve(data.data)
                    })
            })
        },
        

// ***********************************************************
// Reporting
//************************************************************

        getDashboardHistoricBalanceReport: (historicDays, forceUpdate) => {
            return new Promise((resolve) => {
                this.state.getDefaultAccount()
                    .then((accountId) => {
                        if(!accountId || accountId.length === 0) {
                            resolve([])
                            return
                        }
                        if(this.state.cacheStore.historicBalanceDataLoaded && !forceUpdate)
                            {
                                resolve(this.state.cacheStore.historicBalanceData)
                            }
                            else{
                                ReportDataAccessor.getBalanceHistory(accountId, historicDays)
                                .then((result) => {
                                    this.state.setCache('historicBalanceData', result.data.chartData)
                                    resolve(result.data.chartData)
                                })
                            }
                    })

                })
        },

        getForecastBalanceReport(accountId, historicDays) {
            return new Promise((resolve) => {
                ReportDataAccessor.getBalanceForecast(accountId, historicDays)
                .then((result) => {
                    resolve(result.data.chartData)
                })
            })
            
        },

        getHistoricBalanceReport(accountId, historicDays) {
            return new Promise((resolve) => {
                ReportDataAccessor.getBalanceHistory(accountId, historicDays)
                .then((result) => {
                    resolve(result.data.chartData)
                })
            })
            
        },

        getCategoriesOverTimeReport(subCategoryId, fromDate, toDate, granularity, includeCurrent) {
            return new Promise((resolve) => {
                ReportDataAccessor.getCategoriesOverTime(subCategoryId, fromDate, toDate, granularity, includeCurrent)
                .then((result) => {
                    resolve(result.data)
                })
            })
        },
        
        getCategoryAmountReport(categoryId, fromDate, toDate, historicDays) {
            return new Promise((resolve) => {
                ReportDataAccessor.getCategoryAmount(categoryId, fromDate, toDate, historicDays)
                .then((result) => {
                    resolve(result.data)
                })
            })
            
        },

        getDashboardForcastBalanceReport: (forceUpdate) => {
            return new Promise((resolve) => {
                this.state.getDefaultAccount()
                    .then((accountId) => {
                        if(!accountId || accountId.length === 0) {
                            resolve([])
                            return
                        }
                        if(this.state.cacheStore.forecastBalanceDataLoaded && !forceUpdate) {
                            resolve(this.state.cacheStore.forecastBalanceData)
                        }
                        else
                        {
                            ReportDataAccessor.getBalanceForecast(accountId, 14)
                                .then((result) => {
                                    if(result.ok) {
                                        this.state.setCache('forecastBalanceData', result.data.chartData)
                                    }
                                    resolve(result)
                                })
                        }
                        
                    })

                })
            
        },

        getBudgetReport(budgetTypeId, budgetId) {
            return new Promise((resolve) => {
                ReportDataAccessor.getBudgetReport(budgetTypeId, budgetId)
                .then((result) => {
                    resolve(result.data)
                })
            })
            
        },
       
    }


    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        )
    }
}

export const Consumer = Context;