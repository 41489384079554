import React from 'react'
import { Col } from 'react-bootstrap'
import { AccountTypes } from '../Constants'
import Moment from 'react-moment'
import { TransactionTypes } from '../Constants'

class TransactionsLine extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            thisAccountId: this.props.thisAccountId,
            ...this.props.transaction,
            payee: this.calculatePayee(this.props.thisAccountId, this.props.transaction),
            transactionType: this.calculateTransactionType(this.props.thisAccountId, this.props.transaction),
            isDebit: this.calculateIfIsDebit(this.props.thisAccountId, this.props.transaction)
        }
    }

    
    componentWillReceiveProps(newProps) {

        this.setState( {
            ...newProps.transaction,
            payee: this.calculatePayee(newProps.thisAccountId, newProps.transaction),
            transactionType: this.calculateTransactionType(newProps.thisAccountId, newProps.transaction),
            isDebit: this.calculateIfIsDebit(newProps.thisAccountId, newProps.transaction)
        })

    }

    calculatePayee(thisAccountId, transaction) {
       return thisAccountId === transaction.debitAccountId ? transaction.creditAccountName : transaction.debitAccountName
    }

    calculateTransactionType(thisAccountId, transaction) {
        const direction = transaction.debitAccountId === thisAccountId ? 'to' : 'from'
        return `${transaction.transactionType} ${direction}`
    }

    calculateIfIsDebit(thisAccountId, transaction) {


        if(!thisAccountId) {
            return transaction.debitAccountTypeId == AccountTypes.BANKACCOUNT && transaction.creditAccountTypeId == AccountTypes.THIRDPARTY
        }

        if( thisAccountId === transaction.debitAccountId ) {
            return true
        }
        return false
    }

    

    render() {

        const transactionTypeMultiplier = this.state.transactionTypeId === TransactionTypes.PAYMENT ? -1 : this.state.transactionTypeId === TransactionTypes.DEPOSIT ? 1 : 0;

        const deposit = <i className="far fa-arrow-alt-circle-up incomeColor"></i>
        const payment = <i className="far fa-arrow-alt-circle-down expenseColor"></i>
        const transfer = <i className="far fa-arrow-alt-circle-down transferColor"></i>
        

        const amountColumn = <Col md="2" 
            className= {transactionTypeMultiplier === -1 ? 'text-right expenseColor' : transactionTypeMultiplier === 1 ? 'text-right incomeColor' : 'text-right transferColor' }>
                    <strong>
                        {Number.parseFloat(this.state.amount).toFixed(2)}
                    </strong>
                </Col>

        const descriptionColumn = 
            <Col md="4">
                {!this.state.isSplit && 
                    <React.Fragment>
                        <small>{this.state.categoryName} - {this.state.subCategoryName}</small>
                    </React.Fragment>
                }
                {this.state.isSplit &&
                    <React.Fragment>
                        <small>Multiple Categories</small>
                    </React.Fragment>
                } 
                <br />
                {this.state.description}
            </Col>


        return (
           <React.Fragment>
            {!this.state.thisAccountId &&
                <Col md="2">
                    <strong><Moment format="Do MMM 'YY">{this.state.transactionDate}</Moment></strong>
                </Col>
            }
                <Col md="4">
                    <small>
                        {this.state.transactionType} {this.state.isDebit && payment}{this.state.isDebit === false && deposit}
                    </small>
                    <br />
                    <strong>
                        {this.state.payee}
                    </strong>
                    {!this.state.thisAccountId &&
                        <React.Fragment>
                            &nbsp;to {this.state.creditAccountName}
                        </React.Fragment>
                            
                    }
                </Col>
                {descriptionColumn}
                {amountColumn}
                {this.state.thisAccountId &&
                    <Col md="2" className="text-right">
                        <small>
                            {Number.parseFloat(this.state.runningBalance).toFixed(2)}
                        </small>
                    </Col>
                }
            </React.Fragment>
        )
    }
}

export default TransactionsLine